.addplace-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Heebo-VariableFon";
  text-align: center;
  direction: rtl;
  background-color: var(--backgroud-color);
  margin: 5px auto;
  padding: 8px;
  max-width: 500px;
}

.region,
.firstDates{
  display: inline-block;
  /* background-color: var(--btn-color); */
  padding: 7px;
  margin: 2px;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  border-radius: 10px;
  width: 23%;
  height: 48px;
  white-space: normal;
  text-align: center;
  border: 1px solid var(--border-color);
  position: relative;
  color: var(--btnText-color);
  direction: rtl;
  -webkit-appearance: none;
}

.stage{
  width: 30%;
}

.titleplace{
  /* height: 20px; */
  font-size: 14px;
  color: var(--btnText-color);
  text-align: center;
  resize: none;
  font-family: "Heebo-VariableFon";
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 4px;
  border-color: beige;
  padding: 5px;
}

.narrow{
  width: 100px !important;
  margin-left: 10px !important;
  margin-top: -5px !important;
  
}

.cupon-input{
  width: 140px;
  margin-right: 0px;
  margin-top: -5px;
}

@media screen and (min-width: 600px) {

  .firstDates {
    top:-6px;
  }


}
