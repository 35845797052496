.addplace-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: "Heebo-VariableFon";
  text-align: center;
  direction: rtl;
  background-color: var(--backgroud-color);
  margin: 5px auto;
  padding: 8px;
  max-width: 500px;
}

.region,
.firstDates{
  display: inline-block;
  /* background-color: var(--btn-color); */
  padding: 7px;
  margin: 2px;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  border-radius: 10px;
  width: 23%;
  height: 48px;
  white-space: normal;
  text-align: center;
  border: 1px solid var(--border-color);
  position: relative;
  color: var(--btnText-color);
  direction: rtl;
  -webkit-appearance: none;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.stage{
  width: 30%;
}

.titleplace{
  /* height: 20px; */
  font-size: 14px;
  color: var(--btnText-color);
  text-align: center;
  resize: none;
  font-family: "Heebo-VariableFon";
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 4px;
  border-color: beige;
  padding: 5px;
}

.narrow{
  width: 100px !important;
  margin-left: 10px !important;
  margin-top: -5px !important;
  
}

.linePlace{
  font-size: 14px;
  color: var(--btnText-color);
  text-align: center;
  padding: 5px;
  display: flex;
  background-color: white;
  /* opacity: .7; */
  /* width: var(--wide-btn-width); */
  height: 22px;
  margin: 4px auto;
  border-radius: 2px;
  line-height: 22px;
  cursor: pointer;
  
}

.placeButtonSection{
  font-size: 18px;
  color: var(--border-color);
  text-align: center;
  padding: 1px 5px ;
  padding-bottom: 2px;
  display: flex;
  background-color: white;
  /* width: var(--wide-btn-width); */
  height: 24px;
  margin: 0px auto;
  margin-top: -4px;
  margin-bottom: 3px;
  line-height: 22px;
  display: flex;
  align-items: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  /* margin: 0 10px; */

  /* display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  border-top: 1px solid #ddd; */
}

.divider { 
  border-bottom: 1px solid #cccccc;
  height: 1px;
  margin: 4px 20px;
  margin-top: -5px;

}

.rowNum{
  width:30px
}

.rowTitle{
  min-width:100px;
  text-align:right;
  font-weight: bold;
}

.imagePlace{
  max-height: 200px;
  border-radius: 12px;
  margin: 3px;
  /* width: 200px; */
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 3px 3px white;
  z-index: 0;
}

.likebutton{
  background-image: url("../../images/heartgrey.png");
  background-size: cover;
  height: 20px;
  width: 28px;
  cursor: pointer;
}

.likecount
{
  background-image: url("../../images/heartempty.png");
  background-size: cover;
  height: 18px;
  width: 23px;
  cursor: pointer;
  font-size: 11px;
}

.likebuttonChosen
{
  background-image: url("../../images/heartpink.png");
  background-size: cover;
  height: 20px;
  width: 28px;
  cursor: pointer;
}



@media screen and (min-width: 600px) {

  .firstDates {
    top:-6px;
  }


}

@keyframes mypulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.03); box-shadow: 0 4px 10px rgba(255, 50, 120, 0.3); }
  100% { transform: scale(1); }
}

.enlarge {
  animation: mypulse 2s infinite ease-in-out;  /* Run animation forever */
}

.place-card {
  background: white;;
  padding: 3px 13px; 
  border-radius: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}

.watermark-text {
  /* position: absolute; */
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  padding: 5px 10px;
  border-radius: 5px;
}
