.mirror-form {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

#facebook {
  display: inline-block;
  position: relative;
  /* margin-bottom: 10px; */
}

.miror-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  padding: 5px;
  border-radius: 3px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.parentMirror {
  width: var(--wide-btn-width);
  /* width: 100%; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

form input,
.button {
  width: var(--wide-btn-width);
  height: 37px;
  margin: 14px auto 0;
  padding: 0;
  border-width: 0;
  text-align: center;
  font-size: 20px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  border-radius: 12px;
}

form .button-text-center,
.button-text-center {
  background-color: var(--btn-color);
  width: var(--wide-btn-width);
  height: 37px;
  margin: 14px auto 0;
  padding: 0;
  border-width: 0;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  line-height: 37px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.doubleHeight{
  height: 60px !important;
}


form .button-text-center:hover {
  opacity: var(--opacity-hover-degree);
}

/* .mirorCube:focus {
  background-color: var(--btnPressedeText-color);
  color: var(--btnPressedeText-color);
} */

.mirorCube-selected {
  background-color: var(--btnPressed-color);
  border: 2px solid var(--border-color);

  /* color: var(--btnPressedeText-color); */
}

.mirorCube{
  background-size: cover;
  width: 58px;
  max-width: 70px;
  height: 58px;
  max-height: 70px;
  margin: 4px;
}

.sw1 {
  background-image: url("../imagesmiror/sw1.png");
}
.sw2 {
  background-image: url("../imagesmiror/sw2.png");
}
.sw3 {
  background-image: url("../imagesmiror/sw3.png");
}
.sw4 {
  background-image: url("../imagesmiror/sw4.png");
}

.sw1ex {
  background-image: url("../imagesmiror/sw1.png"), url("./../../images/ex.png");
}
.sw2ex {
  background-image: url("../imagesmiror/sw2.png"), url("./../../images/ex.png");
}
.sw3ex {
  background-image: url("../imagesmiror/sw3.png"), url("./../../images/ex.png");
}
.sw4ex {
  background-image: url("../imagesmiror/sw4.png"), url("./../../images/ex.png");
}

.hw1 {
  background-image: url("../imagesmiror/hw1.png");
}
.hw2 {
  background-image: url("../imagesmiror/hw2.png");
}
.hw3 {
  background-image: url("../imagesmiror/hw3.png");
}
.hw4 {
  background-image: url("../imagesmiror/hw4.png");
}

.hw1ex {
  background-image: url("../imagesmiror/hw1.png"),url("./../../images/ex.png") ;
}
.hw2ex {
  background-image: url("../imagesmiror/hw2.png"),url("./../../images/ex.png") ;
}
.hw3ex {
  background-image: url("../imagesmiror/hw3.png"),url("./../../images/ex.png") ;
}
.hw4ex {
  background-image: url("../imagesmiror/hw4.png"),url("./../../images/ex.png") ;
}
/* 
#color1 {
  background-image: url("../imagesmiror/color1.png");
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: 4px;
}
#color2 {
  background-image: url("../imagesmiror/color2.png");
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: 4px;
}
#color3 {
  background-image: url("../imagesmiror/color3.png");
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: 4px;
}
#color4 {
  background-image: url("../imagesmiror/color4.png");
  background-size: cover;
  width: 24px;
  height: 24px;
  margin: 4px;
} */

.sm1 {
  background-image: url("../imagesmiror/sm1.png");
}
.sm2 {
  background-image: url("../imagesmiror/sm2.png");
}
.sm3 {
  background-image: url("../imagesmiror/sm3.png");
}
.sm4 {
  background-image: url("../imagesmiror/sm4.png");
}

.sm1ex {
  background-image: url("../imagesmiror/sm1.png"), url("./../../images/ex.png");
}
.sm2ex {
  background-image: url("../imagesmiror/sm2.png"), url("./../../images/ex.png");
}
.sm3ex {
  background-image: url("../imagesmiror/sm3.png"), url("./../../images/ex.png");
}
.sm4ex {
  background-image: url("../imagesmiror/sm4.png"), url("./../../images/ex.png");
}



.fm1 {
  background-image: url("../imagesmiror/fm1.png");
}
.fm2 {
  background-image: url("../imagesmiror/fm2.png");
}
.fm3 {
  background-image: url("../imagesmiror/fm3.png");
}
.fm4 {
  background-image: url("../imagesmiror/fm4.png");
}

.fm1ex {
  background-image: url("../imagesmiror/fm1.png"), url("./../../images/ex.png");
}
.fm2ex {
  background-image: url("../imagesmiror/fm2.png"), url("./../../images/ex.png");
}
.fm3ex {
  background-image: url("../imagesmiror/fm3.png"), url("./../../images/ex.png");
}
.fm4ex {
  background-image: url("../imagesmiror/fm4.png"), url("./../../images/ex.png");
}



form .button {
  background-color: var(--btn-color);
  font-family: "Heebo-VariableFon";
  font-size: 20px;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
}

form .error {
  font-size: 12px;
  font-family: "Heebo-VariableFon";
  margin: 0 auto;
  margin-top: 1px;
  margin-bottom: -13px;
  color: red;
}
