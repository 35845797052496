.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: var(--btnPressedeText-color) !important;
}

.rc-slider-handle {
  border: solid 2px var(--btnPressedeText-color) !important;
  border-radius: 4px !important;
  width: 24px !important;
  height: 24px !important;
  margin-top: -11px !important;
  background-color: var(--btnPressed-color) !important;
}

.rc-slider-tooltip-hidden {
  display: unset !important;
}

.rc-slider-tooltip-placement-top {
  top: -33.5px !important;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: rgba(0, 0, 0, 0) !important;
}

.rc-slider-tooltip-inner {
  color: black !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border-top-color: rgba(0, 0, 0, 0) !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0) !important;
  font-size: 14px !important;
  font-weight: bold;
}

.rc-slider-rail {
  background-color: var(--btn-color) !important;
}

.rc-slider {
  width: 94% !important;
  margin-left: 5px;
}
