.questionnaries-form {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  font-family: "Heebo-VariableFon";
}

.questionnaires .title {
  margin: 20px auto 10px;
  width: 240px;
  text-align: center;
  font-size: 24px;
  color: var(--title-color);
}

.questionnaires .title-questionaries {
  margin: 20px auto 10px;
  width: 240px;
  text-align: center;
  font-size: 16px;
  color: var(--title-color);
  font-weight: bold;
  direction: rtl;
}

.questionnaires .questionnaires-container {
  /* background-color: var(--backgroud-color); */
  width: 350px;
  margin: 0 auto;
  border-radius: 3px;
  /* height: 540px; */
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.questionnaires .questionnaires-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  height: 100%;
  /* margin-top: 20px; */
  padding: 5px;
  overflow: scroll;
  margin-bottom: 45px;
}

.questionnaires .questionnaires-body .button {
  background-color: var(--btn-color);
  color: var(--btnText-color);
  font-size: 20px;
  /* width: max-content; */
  padding: 10px;
  height: unset;
  margin: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
}

.button:checked {
  background-color: var(--btnPressed-color);
  color: var(--btnPressedeText-color);
}

.questionnaires .questionnaires-body .questionnaire-btn {
  height: 34px;
  line-height: 34px;
  width: var(--home-btn-width);
  min-width: 100px;
  font-size: 18px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  border-radius: 10px;
  margin: 10px auto;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
  background-color: var(--btn-home-color);
  /* background-image: linear-gradient(
    to bottom right,
    var(--btn-color),
    var(--border-color)
  ); */
}

.details-not-good{
  color:red;
  float: right;
  padding-right: 4px;
}

.collabtntext{
  color: var(--btnText-color);
  float: right;
  padding-right: 4px;
}

.questionnaires .questionnaires-body .questionnaire-btn-grey {
  height: 34px;
  line-height: 34px;
  width: var(--home-btn-width);
  min-width: 100px;
  font-size: 18px;
  /* border: 2px solid var(--border-color); */
  /* display: flex; */
  align-items: center;
  /* justify-content: center; */
  font-weight: bold;
  background-color: var(--grey-color);
  opacity: 0.45;
  direction: rtl;
  margin: 5px auto;
  cursor: default;
  /* text-align: right; */
}

.questionnaires .questionnaires-body .questionnaire-btn-border {
  height: 34px;
  line-height: 34px;
  width: var(--home-btn-width);
  min-width: 100px;
  font-size: 18px;
  border: 1px solid var(--btn-color);
  align-items: center;
  font-weight: bold;
  direction: rtl;
  margin: 5px auto;
  background-color: var(--btn-home-color);
 
}


.btn-questionnaire-text {
  float: right;
}

/* .questionnaires-buttons a:focus,
.questionnaires-buttons a:hover {
  border: red solid 2px;
  box-shadow: 0 0 0 2px color;
  opacity: 0.7;
} */

.questionnaires .questionnaires-body .questionnaire-btn:focus,
.questionnaires .questionnaires-body .questionnaire-btn:hover,
.questionnaires .questionnaires-body .questionnaire-btn-border:hover {
  /* border: red solid 2px; */
  box-shadow: 0 0 0 2px color;
  opacity: var(--opacity-hover-degree);
}

.questionnaires .questionnaires-body .questionnaires-buttons {
  /* display: flex; */
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin: 7px;
}

#backBtn {
  width: var(--wide-btn-width);
  height: 37px;
  line-height: 37px;
  margin: 14px auto 0;
  padding: 0;
  text-align: center;
  font-size: 20px;
  font-family: "Heebo-VariableFon";
  border-radius: 12px;
  vertical-align: middle;
  cursor: pointer;
}

.whiteBackNBorder{
  background: white !important;
  border: 2px solid var(--btn-color) !important;
}

#backBtn:active {
  transform: scale(0.97); /* מכווץ את הכפתור טיפה בלחיצה */
  opacity: 0.9;
}

.newGentleBack{
  font-size: 16px !important;
  text-decoration: underline;
}

#backBtn:hover {
  opacity: var(--opacity-hover-degree);
}

.time-till-date-counter-contianer {
  text-align: center;
  direction: rtl;
  padding-bottom: 12px;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  border-radius: 2px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-in-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2px;
}

.slider-in-toggle:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2px;
}

input:checked + .slider-in-toggle {
  background-color: var(--btn-home-color);
}

input:focus + .slider-in-toggle {
  box-shadow: 0 0 1px var(--hoverColor);
}

input:checked + .slider-in-toggle:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle-line {
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  position: relative;
  /* margin-top: -10px; */
  box-sizing: border-box;
  /* width: 90%; */
}

.aboutmeiconw {
  background-image: url("../../images/aboutmeiconw.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.aboutmeiconm {
  background-image: url("../../images/aboutmeiconm.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.aboutpartnericonw {
  background-image: url("../../images/aboutpartnericonw.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.aboutpartnericonm {
  background-image: url("../../images/aboutpartnericonm.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.miroricon {
  background-image: url("../../images/mirornew.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.picicon {
  background-image: url("../../images/picicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.editicon {
  background-image: url("../../images/editicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.fundatesicon {
  background-image: url("../../images/fundates.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.doublematchicon {
  background-image: url("../../images/doublematchicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  display: inline-block;
  float: left;
}

.heart-date {
  background-image: url("../../images/heart-date.png");
  background-size: cover;
  width: 46px;
  height: 37px;
  display: inline-block;
  float: left;
}

.friendlytag{
  background-image: url("../../images/friendlytag.png");
  background-size: cover;
  width: 46px;
  height: 37px;
  background-color: gold;
  display: inline-block;
  float: left;
}


.msgicon {
  background-image: url("../../images/msgicon.png");
  background-size: cover;
  width: 40px;
  height: 37px;
  display: inline-block;
  float: left;
}

.forumicon {
  background-image: url("../../images/forumicon.png");
  background-size: cover;
  width: 42px;
  height: 37px;
  display: inline-block;
  float: left;
}

.presenticon {
  background-image: url("../../images/presenticon.png");
  background-size: cover;
  width: 42px;
  height: 36px;
  display: inline-block;
  float: left;
}

.doublefriend {
  background-image: url("../../images/doublefriend.png");
  background-size: cover;
  width: 42px;
  height: 36px;
  display: inline-block;
  float: left;
}


.owlicon {
  background-image: url("../../images/owlicon.png");
  background-size: cover;
  width: 42px;
  height: 36px;
  display: inline-block;
  float: left;
}

.addplaceicon {
  background-image: url("../../images/addplaceicon.png");
  background-size: cover;
  width: 36px;
  height: 36px;
  display: inline-block;
  float: left;
}

.findplaceicon {
  background-image: url("../../images/findplaceicon.png");
  background-size: cover;
  width: 36px;
  height: 36px;
  display: inline-block;
  float: left;
}

#sub-header {
  user-select: none;
  width: 100%;
  /* max-width: 400px; */
  font-size: 24px;
  font-family: "Heebo-VariableFon";
  color: var(--title-color);
  margin: 0px auto 10px;
  text-align: center;
  position: absolute;
  top: 52px;
  background-color: white;
  /* background-color:var(--border-color); */
  z-index: 1;
}

.text-sub-header {
  color: aliceblue !important ;
  font-size: 16px;
  /* padding: 5px; */
  direction: rtl;
  height: 68px;
}

.exit-sub-header {
  float: right;
  /* color: var(--btnText-color); */
  color: black;
  font-size: 18px;
  font-weight: 600;
  padding: 5px;
  margin-right: 5px;
}
/*.questionnaires .questionnaires-body .questionnaires-buttons :last-child {
    background-color: #00A69C;
    color: mediumturquoise;
}*/

.exit-popup{
  float: right;
  color: black;
  font-size: 16px;
  padding: 5px;
  cursor: pointer;
}

#popupWhatsup{
  background-color: white;
  /* opacity: 0.91; */
  /* z-index: 1; */
  width: 220px;
  padding: 5px; 
  direction: rtl;
  position: absolute;
  top: 160px;
  margin: 0px auto;
  border: 1px solid black;
  text-align: center;
  /* display: block; */
  left: 0px;
  right: 0px;
}

.newUserPopup{
  background-color: white;
  z-index: 4;
  min-width: 80px;
  max-width: 220px;
  padding: 5px; 
  direction: rtl;
  position: absolute;
  top: 190px;
  margin: 5px auto;
  border: 1px solid black;
  text-align: center;
  display: block;
  margin-left: 17px;
  margin-right: 17px;
}

.doubleHeight{
  height: 60px;
}body {
  margin: 0;
  padding: 0;
  background: #000;
  overflow: hidden; }


