.subscribe-form{
  display: grid;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  direction: rtl;
  background-color: var(--backgroud-color);
  margin: 4px auto;
  padding: 8px;
  max-width: 500px;
  margin-bottom: 40px;
  border: 10px white solid;

}

.flower1 {
  /* background-image: url("../../images/flower1.png"); */
  background-image: url("../../images/oneperson.png");
  background-size: cover;
  width: 34px;
  height: 34px;
  float: left;
  margin-left: -9px;
  margin-top: -40px;
}

.flower2 {
  /* background-image: url("../../images/flower2.png"); */
  background-image: url("../../images/twoperson.png");
  background-size: cover;
  width: 43px;
  height: 34px;
  float: left;
  margin-left: -9px;
  margin-top: -40px;
}

.flower3 {
  /* background-image: url("../../images/flower2.png"); */
  background-image: url("../../images/personheart.png");
  background-size: cover;
  width: 54px;
  height: 48px;
  float: left;
  margin-left: -9px;
  margin-top: -40px;
}

.star {
  background-image: url("../../images/star.png");
  background-size: cover;
  width: 38px;
  height: 34px;
  float: left;
  margin-left: -9px;
}

.animate {
  animation: skew 3s infinite;
  transform: skew(20deg);
  animation-direction: alternate;
}

.whatsupp-subscribe .lbl {
  width: 200px;
}

.whatsupp-subscribe .whatsupp {
  background-image: url("./../../images/whatsuppicon.png");
  margin: 3px;
  width: 46px;
  height: 46px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px auto;
}

.whatsupp-subscribe .whatsupp:hover {
  /* background-color: var(--hoverColor); */
  opacity: var(--opacity-hover-degree);
}

.helloline{
  display: flex;
  justify-content: right;
  align-items: right;
  padding-top: 5px;
  box-sizing: border-box;
  max-width: 200px;
  margin-right: 10px !important;
}

.lbl_hello{
  direction: rtl;
  white-space: pre-line;
  font-size: 15px;
  /* float: right; */
  margin-top: 12px !important;
  margin-right: 3px;
}

.parent-payRoute{
  display: flex;
  justify-content: center;
  align-items: center;
}

.payRoutebtn {
  cursor: pointer;
  line-height: 25px;
  font-size: 14px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  background-color: var(--btn-color);
  padding: 12px;
  height: unset;
  width: 52%;
  height: 50px;
  margin: 14px auto 0;
  border-width: 0;
  border-radius: 3px;
  color: var(--btnPressedeText-color);
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.newUser {
  width: 80%;
  border: 3px var(--btnPressed-color) solid
}

.payRoutebtn:hover {
  opacity: var(--opacity-hover-degree);
}

.subscribe-inputs{
  display: contents;
}

.like-input{
  width: var(--wide-btn-width);
  height: 37px;
  line-height: 37px;
  margin: 14px auto 0;
  padding: 0;
  border-width: 0;
  text-align: center;
  font-size: 20px;
  font-family: "Heebo-VariableFon";
  background-color: white;
  color: var(--btnText-color);
  border-radius: 3px;
}

.active {
  background-color: var(--btnPressed-color) !important;
  color: var(--btnPressedeText-color);
  border: 2px solid var(--border-color);
}

.ifarme{
  height: 450px;
  width: var(--wide-btn-width);
  margin: 0 auto;
}

.shadowcard{
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
}

.right{
  text-align: right;
}