/* :root {
  --backgroud-color: rgb(3, 156, 184);
  --container-color: rgb(41, 174, 197);
  --border-color: #fdfbf7;
  --title-color: #473012;
  --btn-color: #f0eee9;
  --btnText-color: #4b4b49;
  --btnPressed-color: #f39898;
  --btnPressedeText-color: #665c42;
} */

:root {
  /* --backgroud-color: #e5f0f1; */
  --backgroud-color: whitesmoke;
  --container-color: rgb(247, 247, 244);
  /* --border-color: #848383; */
  /* --border-color: #f0358c; */
  --border-color: #ff4081;
  --title-color: #272726;
  --btn-color: #ff4081;
  --btn-home-color: #86e5f6;
  --btnText-color: #272726;
  /* --btnPressed-color: #f2b807; */
  --btnPressed-color: #86e5f6;
  --btnPressedeText-color: #272726;
  --hoverColor: #ff4081;
  /* --page-title-background-color: #d9afaf; */
  --page-title-background-color:#f2d1d1;
  --exitcolor: #d1d98b;
  --grey-color: #a0a09f;
  --opacity-hover-degree: 0.7;
  --container-width: 83%;
  --body-inside-container-width: 83%;
  --wide-btn-width: 90%;
  --home-btn-width: 90%;
  --max_width_fullscreen: 550px;
}

/* :root {
  --backgroud-color: rgb(88, 88, 87);
  --container-color: rgb(233, 238, 190);
  --border-color: #f4f5f8;
  --title-color: #fafafd;
  --btn-color: #e0d974;
  --btnText-color: #272726;
  --btnPressed-color: #dbdbd2;
  --btnPressedeText-color: #494947;
  --hoverColor: #f7fa17;
} */

.double {
  /* background-color: var(--backgroud-color); */
  /* background-image: url("./images/myBackground.png"); */
  /* opacity: 0.7; */
  /* background-image: url("./images/background-cubes.png"); */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  background-color: #f2f2f2;
  background: white;
  background-image: radial-gradient(var(--btn-color), 1px, transparent 0);
  background-size: 10px 10px;
  background-position: -19px -19px;
  /* #dbebec; */
  flex-direction: column;
  font-family: "Heebo-VariableFon";
}

.double .content {
  background-color: var(--backgroud-color);

  /* border: 2px solid var(--border-color); */
  max-width: 400px;
  margin: 20px auto;
  height: 93%;
  width: 88%;
  border-radius: 9px;
  padding: 10px;
  overflow: scroll;
}

.double .content .hp-logo {
  /* background-image: url("./images/logo-double-date.png"); */
  background-image: url("./images/homepage-logo-color.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: auto;
  max-height: 300px;
  margin: 15px auto 0;
}

.homepage-photo {
  background-image: url("./images/homepage-photo.png");
  width: 100%;
  height: 372px;
  background-size: cover;
  position: relative;
}

.co {
  background-image: url("./images/co.png");
  width: 16px;
  height: 16px;
  background-size: cover;
  position: relative;
  margin: 4px;
}

.mati {
  background-image: url("./images/mati.png");
  width: 50px;
  height: 24px;
  background-size: cover;
  position: relative;
  margin: 4px;
}

.maof {
  background-image: url("./images/maof.png");
  width: 50px;
  height: 24px;
  background-size: cover;
  position: relative;
  margin: 4px;
}


.taciru {
  background-image: url("./images/taciru.png");
  width: 60px;
  height: 24px;
  background-size: cover;
  position: relative;
  margin: 4px;
}

.goodvibes {
  background-image: url("./images/goodvibes.png");
  width: 60px;
  height: 24px;
  background-size: cover;
  position: relative;
  margin: 4px;
}

.adtopmainscreen {
  /* background-image: url("./images/webinarEli.png"); */
  /* background-image: url("./images//topad2.png"); */
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.arrowleft {
  background-image: url("./images/arrowleft.png");
  /* width: 1px; */
  height: 30px;
  background-size: cover;
  margin: 4px;
  position: absolute;
  left: 20px;
  padding: 20px;
}

.arrowright {
  background-image: url("./images/arrowright.png");
  /* width: 1px; */
  height: 30px;
  background-size: cover;
  margin: 4px;
  position: absolute;
  right: 20px;
  padding: 20px;
}

.lineDice {
  background-image: url("./images/linedice.png");
  background-size: cover;
  width: var(--wide-btn-width);
  max-width: 400px;
  height: 35px;
  max-height: 40px;
  margin: 10px auto 0;
}

.line {
  height: 5px;
  border: none;
  border-top: 5px dotted #fff;
  color: #fff;
  width: var(--wide-btn-width);
  max-width: 400px;
  height: 7px;
  margin: 5px auto 5px;
  margin-bottom: 15px;
}

.infobtn {
  background-image: url("./images/infobtn.png");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin: 16px auto 0;
  /* border: 2px solid var(--border-color); */
  border-radius: 16px;
  background-color: var(--border-color);
  opacity: 0.6;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.helphand {
  background-image: url("./images/helpicon.png");
  background-size: cover;
  width: 40px;
  height: 40px;
  margin: 16px auto 0;
  /* border: 2px solid var(--border-color); */
  border-radius: 8px;
  background-color: var(--page-title-background-color);
  cursor: pointer;
}


.helphand-without-margin {
  background-image: url("./images/helpicon.png");
  background-size: cover;
  width: 40px;
  height: 40px;
  /* border: 2px solid var(--border-color); */
  border-radius: 8px;
  background-color: var(--page-title-background-color);
  cursor: pointer;
}

.helphand:hover,
.helphand-without-margin:hover{
  opacity: 0.6;
}

.shadow{
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    touch-action: manipulation;
    will-change: transform,opacity;
}

.infobtn-without-margin {
  background-image: url("./images/infobtn.png");
  background-size: cover;
  width: 40px;
  height: 40px;
  /* border: 2px solid var(--border-color); */
  border-radius: 16px;
  background-color: var(--border-color);
  opacity: 0.6;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.infobtn:hover,
.infobtn-without-margin:hover {
  background-color: var(--hoverColor);
}

.faceicon {
  background-image: url("./images/faceicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  background-color: var(--page-title-background-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.instegramicon {
  background-image: url("./images/instegramicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  background-color: var(--page-title-background-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.instegramrealicon{
  background-image: url("./images/instegramrealicon.png");
  width: 40px;
  height: 38px;
  margin-top: 3px !important;
}


.linkedinicon {
  background-image: url("./images/linkedinicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  background-color: var(--page-title-background-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.cameraicon {
  background-image: url("./images/cameraicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  background-color: var(--page-title-background-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.whatsuplink {
  background-image: url("./images/whatsuppicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  /* background-color: var(--page-title-background-color); */
  border-radius: 3px;
}

.whatsuppgrey {
  background-image: url("./images/whatsuppgrey.png");
  background-size: cover;
  width: 20px;
  height: 20px;
  margin: 1px 8px;
  margin-top: 4px;
  display: inline-block;
  /* background-color: var(--page-title-background-color); */
  border-radius: 3px;
}

.recordicon {
  background-image: url("./images/recordicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  background-color: var(--page-title-background-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.faceicongrey {
  background-image: url("./images/faceicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  opacity: 0.45;
  background-color: var(--grey-color);
  border-radius: 3px;
}

.instegramicongrey {
  background-image: url("./images/instegramicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  opacity: 0.45;
  background-color: var(--grey-color);
  border-radius: 3px;
}

.linkedinicongrey {
  background-image: url("./images/linkedinicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  opacity: 0.45;
  background-color: var(--grey-color);
  border-radius: 3px;
}

.cameraicongrey {
  background-image: url("./images/cameraicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  opacity: 0.45;
  border-radius: 2px;
  background-color: var(--grey-color);
  border-radius: 3px;
}

.recordicongrey {
  background-image: url("./images/recordicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  opacity: 0.45;
  border-radius: 2px;
  background-color: var(--grey-color);
  border-radius: 3px;
}

.pause-icon {
  background-image: url("./images/stopplayicon.png");
}

.play-icon {
  background-image: url("./images/playicon.png");
}

.record-icon {
  background-image: url("./images/recordicon.png");
}
.recordicon-aboutme {
  /* background-image: url("./images/recordicon.png"); */
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 1px 8px;
  display: inline-block;
  background-color: var(--btn-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.playicon-button {
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: auto 8px;
  display: inline-block;
  background-color: var(--btn-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.stopplayicon {
  background-image: url("./images/stopplayicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: auto 8px;
  display: inline-block;
  background-color: var(--btn-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.deleteicon {
  background-image: url("./images/deleteicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: auto 8px;
  display: inline-block;
  background-color: var(--btn-color);
  border-radius: 3px;
}

.infoicon {
  background-image: url("./images/infobtn.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  margin: 14px 3px 0;
  display: inline-block;
  background-color: var(--page-title-background-color);
  border-radius: 3px;
}

.cellularicon {
  background-image: url("./images/cellular.png");
  background-size: cover;
  width: 26px;
  height: 26px;
  margin: 6px;
  display: inline-block;
}

.sendicon {
  background-image: url("./images/sendicon.png");
  background-size: cover;
  width: 42px;
  height: 37px;
  display: inline-block;
}

.uploadicon {
  background-image: url("./images/uploadicon.png");
  background-size: cover;
  width: 18px;
  height: 18px;
  display: inline-block;
}

.uploadicon:hover,
.infoicon:hover,
.faceicon:hover,
.instegramicon:hover,
.linkedinicon:hover,
.infobtn:hover,
.cameraicon:hover,
.recordicon:hover,
.playicon:hover,
.recordicon-aboutme:hover,
.playicon-button:hover {
  background-color: var(--hoverColor);
}

.moreicon {
  background-image: url("./images/moreicon.png");
  background-size: cover;
  width: 28px;
  height: 14px;
  display: inline-block;
  opacity: var(--opacity-hover-degree);
  margin: 2px;
}

.moreicon:hover {
  background-color: var(--hoverColor);
}

.nickicon {
  background-image: url("./images/nickicon.png");
  background-size: cover;
  width: 24px;
  height: 22px;
  display: inline-block;
  margin: 2px;
  border-radius: 16px;
  background-color: var(--border-color);
  opacity: 0.6;
}

.circle_2icon {
  background-image: url("./images/circle_2.png");
  background-size: cover;
  width: 40px;
  height: 38px;
  display: inline-block;
  /* margin: 2px; */
  float: left;
}

.circle_1icon {
  background-image: url("./images/circle_1.png");
  background-size: cover;
  width: 40px;
  height: 38px;
  display: inline-block;
  /* margin: 2px; */
  float: left;
}

.nickicon:hover {
  background-color: var(--hoverColor);
}

.infoicon-empty {
  background-image: url("./images/info.png");
  background-size: cover;
  width: 22px;
  height: 22px;
  margin: 0 6px;
  display: inline-block;
  border-radius: 16px;
  background-color: var(--border-color);
  opacity: 0.6;
}

.infoicon-empty:hover {
  background-color: var(--hoverColor);
}

.zoomdatelogo {
  background-image: url("./images/zoomdatelogo.jpeg");
  background-size: cover;
  width: 50px;
  height: 33px;
  display: inline-block;
  float: left;
}

.full-screen {
  height: 100vh;
 
}

form .error {
  font-size: 12px;
  font-family: "Heebo-VariableFon";
  margin: 0 auto;
  margin-top: 1px;
  margin-bottom: -13px;
  color: red;
}

@font-face {
  font-family: "Heebo-VariableFon";
  font-style: normal;
  /* font-weight: 400; */
  src: local("Heebo-VariableFont"),
    url(./assets/fonts/Heebo-VariableFont_wght.ttf) format("truetype");
}


.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  perspective: 800px; /* עומק אמיתי */
}

/* עטיפת הקוביות */
.scene {
  width: 150px;
  height: 100px;
  display: flex;
  justify-content: space-between;
}

/* הקוביות */
.cube {
  width: 50px;
  height: 50px;
  position: relative;
  transform-style: preserve-3d;
}

/* קובייה ראשונה (תכלת) - מסתובבת עם האטה */
.cube1 {
  animation: rollClockwise 2s infinite cubic-bezier(0.25, 1, 0.5, 1);
}

.cube1 .face {
  background: var(--btn-home-color); /* תכלת */
  color: white;
}

/* קובייה שנייה (ורודה) - מסתובבת נגד כיוון השעון עם האטה */
.cube2 {
  animation: rollCounterClockwise 2s infinite cubic-bezier(0.25, 1, 0.5, 1);
}

.cube2 .face {
  background:var(--border-color); /* ורוד */
  color: white;
}

/* פאות הקובייה */
.face {
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border: 2px solid white;
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

/* הוספת לב לכל פאה */
.face::after {
  content: "🤍";
  font-size: 22px;
  color: white; /* הלב נשאר לבן */
}

/* מיקום כל פאה */
.front  { transform: translateZ(25px); }
.back   { transform: rotateY(180deg) translateZ(25px); }
.left   { transform: rotateY(-90deg) translateZ(25px); }
.right  { transform: rotateY(90deg) translateZ(25px); }
.top    { transform: rotateX(90deg) translateZ(25px); }
.bottom { transform: rotateX(-90deg) translateZ(25px); }

/* אנימציה של גלגול עם האטה */
@keyframes rollClockwise {
  0%   { transform: rotateX(0deg) rotateY(0deg); }
  25%  { transform: rotateX(90deg) rotateY(0deg); }
  50%  { transform: rotateX(90deg) rotateY(90deg); }
  75%  { transform: rotateX(180deg) rotateY(90deg); }
  100% { transform: rotateX(360deg) rotateY(360deg); }
}

@keyframes rollCounterClockwise {
  0%   { transform: rotateX(0deg) rotateY(0deg); }
  25%  { transform: rotateX(-90deg) rotateY(0deg); }
  50%  { transform: rotateX(-90deg) rotateY(-90deg); }
  75%  { transform: rotateX(-180deg) rotateY(-90deg); }
  100% { transform: rotateX(-360deg) rotateY(-360deg); }
}
