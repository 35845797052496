/* .Footer {
  background-color: var(--page-title-background-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: absolute;
  bottom: 0px;
  width: 100vw;
  background-image: linear-gradient(
    to bottom right,
    var(--btn-color),
    var(--btnPressed-color)
  );
} */

.Footer {
  /* background-color: var(--container-color); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  position: absolute;
  bottom: 0px;
  width: 100vw;
}

.Footer div,
.Footer a {
  background-size: cover;
  background-repeat: no-repeat;
  width: 40px;
  height: 38px;
   /* margin: 0px auto;  */
}

.footer-text {
  margin: 0px auto;
  /* width: 250px; */
  font-weight: bold;
  font-size: medium;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
  direction: rtl;
}

.Footer .facebook {
  background-image: url("./../../images/facebookicon.png");
  margin: 3px;
  height: 90%;
  width: 90%;
}

.Footer .whatsupp {
  background-image: url("./../../images/whatsuppicon.png");
  margin: 0px 15px;
  margin-bottom: 4px;

}

.Footer .mettillnow,
.sign-up-free {
  /* background-image: url("./../../images/pinkcircle.png"); */
  cursor: pointer;
  border-radius: 90px;
  background-color:var(--container-color);
  border: 10px solid var(--btn-color);
  width:50px;
  height: 50px;
  line-height: 15px;
  font-weight: bold;
  font-size: 12px;
  margin: 0px 30px;
  margin-bottom: 45px;
  padding: 8px;
  padding-top: 11px;
  text-align: center;
  /* animation: skew 3s infinite;
  transform: skew(20deg);
  animation-direction: alternate; */
  direction: rtl;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;

}

.heart-icon1 {
  color: var(--btn-color);; /* צבע הלב */
  font-size: 18px;
  height: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  
}

.heart-icon {
  display: inline-block;
  top: -55px;
  right: 58px;
  position: absolute;
  width: 30px;
  aspect-ratio: 1;
  border-image: radial-gradient( var(--btn-color) 69%, #0000 70%) 84.5%/50%;
  clip-path: polygon(-41% 0, 50% 91%, 141% 0);
  /* border: 3px solid white; */
  /* background: var(--btn-color); */
  /* clip-path: path(M 50,15 C 65,-5 95,5 95,30 
   C 95,50 75,75 50,90 
   C 25,75 5,50 5,30
    C 5,5 35,-5 50,15 Z); */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.1); opacity: 0.9;  border-width: 4px;}
  100% { transform: scale(1); opacity: 1; }
}

.Footer .encourage {
  background-image: url("./../../images/encourageicon.png");
  cursor: pointer;
}

.Footer .encourage:hover,
/* .Footer .whatsupp:hover, */
.Footer .facebook:hover {
  background-color: var(--hoverColor);
}
/* 
@keyframes skew {
  0% {
    transform: skewX(20deg);
  }
  100% {
    transform: skewX(-20deg);
  }
} */
