form {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  font-family: "Heebo-VariableFon";
}

.page-title {
  user-select: none;
  width: 100%;
  /* max-width: 400px; */
  direction: rtl;
  font-size: 20px;
  font-family: "Heebo-VariableFon";
  color: var(--title-color);
  margin: 0px auto 10px;
  text-align: center;
  position: absolute;
  top: 52px;
  background-color: var(--page-title-background-color);
  z-index: 1;
}


/* #h2 {
  margin: 0px auto 10px;
  width: 250px;
  font-size: 12px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
} */

.section-title {
  margin: 0px auto;
  /* width: 250px; */
  font-size: 18px;
  font-weight: 500;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
  direction: rtl;
}

#htext {
  margin: 0px auto;
  width: 220px;
  font-size: 12px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
}

#hright {
  text-align: right;
  display: inline-block;
  float: right;
  font-size: 13px;
  margin-right: 6px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
}

#hleft {
  text-align: left;
  display: inline-block;
  float: left;
  font-size: 13px;
  margin-left: 6px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
}

.birthday {
  width: var(--wide-btn-width);
  margin: 6px auto;
}

.freetext-container {
  width: 95%;
  margin: 0 auto;
}

.freetext {
  /* margin: 5px; */
  width: 98%;
  height: 100px;
  font-size: 16px;
  line-height: 150%;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  /* text-align: center; */
  resize: none;
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 12px;
  margin: 5px auto;
  border-color: beige;
}

.occupation {
  /* margin: 5px; */
  width: 82%;
  height: 28px;
  font-size: 16px;
  font-family: "Heebo-VariableFon";
  color: var(--title-colorbh);
  line-height: 28px;
  text-align: center;
  resize: none;
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 12px;
  border-color: beige;
}

.faceline {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-sizing: border-box;
  width: var(--wide-btn-width);
  margin: auto;
}

.files-dropzone {
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  box-sizing: border-box;
  display: flex;
  width: var(--wide-btn-width);
  margin: auto;
}

#facebook,
#btnUploadPhoto,
#instegram {
  width: 85% !important;
  /* margin-top: 7px; */
  display: inline-block;
  position: relative;
  /* font-size: small; */
  /* margin-bottom: 10px; */
}


#linkedin{
  display: inline-block;
  position: relative;
  /* font-size: small; */
  width: 100% !important;
}

/* #instegram {
  display: inline-block;
  position: relative;
  font-size: small;
  width: var(--wide-btn-width);
  margin: 6px auto;
  padding: 0;
} */

.infobtn,
.helphand {
  display: inline-block;
  /* margin-left: 155px; */
}

.aboutme .aboutme-container {
  background-color: var(--backgroud-color);
  width: var(--container-width);
  max-width: 420px;
  margin: 0 auto;
  border-radius: 3px;
  height: 370px;
  display: flex;
  align-items: center;
}

.aboutme-body {
  text-align: center;
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  padding: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.parent-dat .parent-status .parent-edu .parent-7cubes {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.dati,
.light,
.datito,
.modern,
.hardal,
.trad,
.datil,
.datlash,
.hared,
.hozer,
.hilon {
  display: inline-block;
  /* background-color: var(--btn-color); */
  padding: 2px;
  margin: 2px;
  font-size: 15px;
  font-family: "Heebo-VariableFon";
  border-radius: 10px;
  width: 23%;
  height: 46px;
  white-space: normal;
  text-align: center;
  /* border: 1px solid var(--border-color); */
  position: relative;
  color: var(--btnText-color);
  letter-spacing: 1px;
  line-height: 120%;
  -webkit-appearance: none;
  /* background-image: linear-gradient(
    to bottom right,
    var(--btn-color),
    var(--border-color)
  ); */
}

/* .dati,
.hardal {
  top: 8px;
} */

.hozer,
.datil 
{
  top: 9px;
}

.datito {
  font-size: 13px;
}

.modern{
  font-size: 11px;
  padding: 2px;
  /* font-weight: 600; */
  top: 4px;
}

/* .parent-dat #dati:focus,
#light:focus,
#datito:focus,
#modern:focus,
#hardal:focus { */

/* .parent-status #single, */
.single,
.divo,
.divop,
.widow,
.singleplus,
/* .parent-edu #highschool, */
.highschool,
.student,
.other,
.academy,
.hobby,
.curPos,
.limud,
.seminar,
.eda,
.hesderHared,
.limudTop,
.limudDaf {
  display: inline-block;
  /* background-color: var(--btn-color); */
  padding: 7px;
  margin: 2px;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  border-radius: 10px;
  width: 23%;
  height: 48px;
  white-space: normal;
  text-align: center;
  /* border: 1px solid var(--border-color); */
  position: relative;
  color: var(--btnText-color);
  direction: rtl;
  -webkit-appearance: none;
  /* background-image: linear-gradient(
    to bottom right,
    var(--btn-color),
    var(--border-color)
  ); */
}

.other{
  font-size: 10px;
  margin: 1px;
  padding: 2px;
  top:6px;
}

.limudDaf{
  top:6px
}

.hesderHared{
  font-size: 11px;
  margin: 1px;
  top: -3px
}

.datil{
  font-size: 14px;
}

@media screen and (min-width: 600px) {
  .seminar {
   padding-right: 18px;
   padding-left: 18px;
  }

  .limudTop {
    top:-6px;
  }

  .limudDaf{
   top: 0px
  }



.hesderHared{
  font-size: 12px;
  top: -2px
}

}

@media screen and (min-width: 430px) {

  .datil,
  .datito{
    top:0px  
   }
}


.hobby {
  height: 38px;
}

.dati:hover,
.light:hover,
.datito:hover,
.modern:hover,
.hardal:hover,
.trad:hover,
.datil:hover,
.datlash:hover,
.hared:hover,
.hozer:hover,
.hilon:hover,
.hobby:hover,
.single:hover,
.divo:hover,
.divop:hover,
.widow:hover,
.singleplus:hover,
.highschool:hover,
.student:hover,
.hesderHared:hover,
.other:hover,
.academy:hover,
.hobby:hover,
.characterCube:hover,
.step-about-me-after:hover,
.step-about-me:hover,
.curPos:hover,
.limud:hover,
.eda:hover,
.seminar:hover,
.limudTop:hover,
.limudDaf:hover {
  opacity: var(--opacity-hover-degree);
}

/* .parent-status #single:focus,
#divo:focus,
#divop:focus,
#widow:focus,
.parent-edu #highschool:focus,
#student:focus,
#other:focus,
#academy:focus {
  background-color: var(--btnPressed-color);
  color: var(--btnPressedeText-color);
} */

/* .divop {
  top: -7px;
} */

.characterCube {
  display: inline-block;
  background-color: var(--btn-color);
  padding: 3px;
  margin: 1px;
  font-size: 0px;
  font-family: "Heebo-VariableFon";
  border-radius: 2px;
  width: 12.5%;
  height: 21.7px;
  white-space: normal;
  text-align: center;
  /* border: 1px solid var(--border-color); */
  position: relative;
  color: var(--btnText-color);
  -webkit-appearance: none;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.rightCharacterCube {
  background-image: linear-gradient(
    to left,
    var(--btn-color) 70%,
    var(--btnPressed-color) 30%
  );
  color: unset;
}

.leftCharacterCube {
  background-image: linear-gradient(
    to right,
    var(--btn-color) 70%,
    var(--btnPressed-color) 30%
  );
  color: unset;
}

.lbl {
  direction: rtl;
  white-space: pre-line;
  font-size: 14px;
  /* float: right; */
  margin-top: 4px;
  margin-right: 3px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis; /* יוסיף "..." אם נחתך */
  white-space: normal; /* מרשה ירידת שורה */
  word-wrap: break-word;
}

.smalllbl{
  direction: rtl;
  white-space: pre-line;
  font-size: 11px;
  /* float: right; */
  margin-top: 4px;
  margin-right: 3px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis; /* יוסיף "..." אם נחתך */
  white-space: normal; /* מרשה ירידת שורה */
  word-wrap: break-word;
}

.record-progress-bar {
  width: 76%;
}

.active {
  background-color: var(--btnPressed-color);
  color: var(--btnPressedeText-color);
  border: 2px solid var(--border-color);
}

form .error {
  font-size: 12px;
  font-family: "Heebo-VariableFon";
  margin: 0 auto;
  margin-top: 1px;
  margin-bottom: -13px;
  color: red;
}

.error-message {
  direction: rtl;
  color: red;
}

.chosen-image {
  max-height: 100px;
  border-radius: 2px;
  margin: 3px;
  width: 100px;
  object-fit: cover;
  box-shadow: 0px 0px 3px 3px white;
  z-index: 0;
}

.images-drop-area-container {
  width: 85%;
  margin: 0 auto;
 
}

.images-drop-area {
  border: var(--btnText-color);
  border-style: dashed;
  border-width: 1px;
  border-radius: 12px;
  height: 100px;
  width: 100%;
  margin-top: 14px;
  line-height: 30px;
  font-size: small;
  text-align: center;
}

.remove-image-button {
  border: 1px solid red;
  margin: 0px;
  padding: 4px;
  color: var(--btnText-color);
  background-image: url("../../images/trashicon.png");
  background-size: cover;
  width: 14px;
  height: 14px;
  box-shadow: rgba(0, 0, 0, .2) 0 2px 4px -1px, rgba(0, 0, 0, .14) 0 5px 7px 0, rgba(0, 0, 0, .12) 0 1px 11px 0;
}

.remove-image-button:hover {
  background-color: var(--hoverColor);
}

.loading-data-container {
  text-align: center;
  width: 60vw;
  height: 60vw;
  margin-top: 52px !important;
  border-radius: 16px;
  margin: auto;
  display: table;
  background: var(--btn-color);
}

.loading-data-div {
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.slider-in-toggle-aboutme {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2px;
}

.slider-in-toggle-aboutme:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 2px;
}

input:checked + .slider-in-toggle-aboutme {
  background-color: var(--btn-color);
}

input:focus + .slider-in-toggle-aboutme {
  box-shadow: 0 0 1px var(--btn-color);
}

input:checked + .slider-in-toggle-aboutme:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

audio {
  width: 100% !important;
  height: revert !important;
}

.recorder-tag {
  height: 0px;
}

.recording-premission-error {
  color: red;
}

.recording-filesize-error {
  color: red;
  direction: rtl;
}

.write-with-me {
  width: var(--wide-btn-width);
  height: 37px;
  line-height: 37px;
  margin: 14px auto 0;
  padding: 0;
  border-width: 0;
  text-align: center;
  font-size: 18px;
  font-family: "Heebo-VariableFon";
  background-color: var(--btn-color);
  /* opacity: var(--opacity-hover-degree); */
  border-radius: 12px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  direction: rtl;
  border: 4px solid var(--border-color);
}

.write-with-me:hover {
  opacity: 0.5;
}

.lbl-write-with-me {
  padding-top: 5px;
  direction: rtl;
  margin-top: 5px;
}

.txt-write-with-me {
  width: 95%;
  height: 40px;
  font-size: 14px;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  /* text-align: center; */
  resize: none;
  /* word-break: break-all; */
  direction: rtl;
  white-space: pre-line;
  border-radius: 12px;
  border-color: beige;
}

.step-about-me {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  /* border: "1px grey solid", */
  background-color: var(--container-color);
  color: black;
  text-align: center;
  line-height: 20px;
  padding: 10px;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0; 
}

.step-about-me-after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  /* border: "1px grey solid", */
  background-color: var(--border-color);
  color: black;
  text-align: center;
  line-height: 20px;
  padding: 10px;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;

}

.steps {
  display: flex;
  direction: rtl;
  justify-content: center;
  margin-top: 60px;
  cursor: pointer;
  font-size: 18px;
}

.line-between-step {
  height: 10px;
  /* border: "1px gray solid", */
  z-index: 8;
  width: 64px;
  margin: 17px -5px 0 -5px;
  background-color: var(--container-color);
}

.line-between-step-after {
  height: 7px;
  /* border: "1px gray solid", */
  z-index: 8;
  width: 47px;
  margin: 17px -5px 0 -5px;
  background-color: var(--border-color);
}

.cubesvideo{
  max-width: 300px;
  object-fit: cover;
}

.myRadio{
  width: 16px;
  height: 16px;
  border: 2px solid #999;
  transition: 0.2s all linear;
  margin: 5px;
  accent-color: var(--btn-color);
}
