.about-partner-form {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  font-family: "Heebo-VariableFon";
}

.aboutpartner .aboutpartner-container {
  background-color: var(--backgroud-color);
  width: var(--container-width);
  margin: 0 auto;
  border-radius: 3px;
  height: 370px;
  display: flex;
  align-items: center;
}

.aboutpartner-body {
  background-color: var(--container-color);
  width: var(--body-inside-container-width);
  max-width: 400px;
  margin: 0 auto;
  border-radius: 3px;
  /* border: 1px solid var(--border-color); */
  /* height: 100%; */
  padding: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.page-sub-title {
  margin: 0px auto 10px;
  /* width: 240px; */
  font-size: 18px;
  color: var(--title-color);
  text-align: center;
  margin-top: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.wideex{
  background-image: url("./../../images/wideex.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.linewhite{
  height: 4px;
  max-width: 400px;
  background-color: white;
  margin-bottom: 15px;
}