
.choose_room {
  display: flex;
  flex-direction: column;
  font-family: "Heebo-VariableFon";
  align-items: center;
  margin: 12px;
}

.collabbtn {
  cursor: pointer;
  line-height: 37px;
  min-width: 100px;
  font-size: 18px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  background-color: var(--btn-home-color);
  padding: 10px;
  height: unset;
  width: var(--wide-btn-width);
  height: 37px;
  margin: 14px auto 0;
  /* margin-bottom: "12px"; */ 
  border-width: 0;
  font-family: "Heebo-VariableFon";
  border-radius: 10px;
  flex-direction: row;
  color: var(--btnText-color);
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;

}

.collabbtn:hover {
  opacity: var(--opacity-hover-degree);
}

.camera {
  background-image: url("../../images/camera.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
}

.coffee {
  background-image: url("../../images/coffee.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
}


.therapy {
  background-image: url("../../images/therapyicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
}

.vudu {
  background-image: url("../../images/vudu.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
}

.coupon {
  background-image: url("../../images/couponicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
}

.whatsupFloat {
  background-image: url("../../images/whatsuppicon.png");
  background-size: cover;
  width: 37px;
  height: 37px;
  float: left;
  margin-top: -42px;
}

.smallArticle,
#photographers,
#coupons,
#vudus {
  border: 1px solid var(--btnText-color);
  border-radius: 2px;
  width: var(--wide-btn-width);
  text-align: right;
  margin: 14px auto 0;
  color: var(--btnText-color);
  padding: 10px;
  font-size: 14px;
  direction: rtl;
}

.photographline {
  line-height: 25px;
  min-width: 100px;
  font-size: 14px;
  align-items: center;
  direction: rtl;
  padding: 10px;
  height: unset;
  /* width: var(--wide-btn-width); */
  height: 37px;
  margin: 14px auto 0;
  border-width: 0;
  font-family: "Heebo-VariableFon";
  border-radius: 3px;
  flex-direction: row;
}

.displayNone {
  display: none;
}

.photographCol {
  flex-direction: column;
}

.h1-center {
  margin: 4px auto;
  color: var(--title-color);
  font-weight: bold;
}

.sectionTitle {
  margin: 0px auto;
  /* width: 250px; */
  font-size: 14px;
  font-weight: bold;
  font-family: "Heebo-VariableFon";
  color: var(--btnText-color);
  text-align: center;
  direction: rtl;
}

.collabbtn-grey {
  line-height: 37px;
  min-width: 100px;
  font-size: 18px;
  align-items: center;
  font-weight: bold;
  direction: rtl;
  background-color: grey;
  padding: 10px;
  height: unset;
  width: var(--wide-btn-width);
  height: 37px;
  margin: 14px auto 0;
  border-width: 0;
  font-family: "Heebo-VariableFon";
  border-radius: 10px;
  flex-direction: row;
}

.subTitle2{
  font-size: 13px;
}

.sarashabat {
  background-image: url("../../images/sarashabat.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.noamkamin {
  background-image: url("../../images/noamkamin.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.nadavravak {
  background-image: url("../../images/nadavravak.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.nadavkurayev{
  background-image: url("../../images/nadavkurayev2.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.oribor{
  background-image: url("../../images/oribor.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.izharweb{
  background-image: url("../../images/izharweb.jpg");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.topadreview{
  background-image: url("../../images/topadreview.jpeg");
  width: 300px;
  height: 36px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
  margin-top:0px;
}

.everyonevip {
  background-image: url("../../images/everyonevip.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.coffeecupon {
  background-image: url("../../images/coffeecupon.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.adencourage {
  background-image: url("../../images/topad.jpeg");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.tubishvat{
  background-image: url("../../images/purimad.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
}

.uriaAI{
  background-image: url("../../images/uriaAI.png");
  width: 325px;
  height: 65px;
  background-size: cover;
  position: relative;
  margin: 0px auto;
  border-radius: 18px;
}
